import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"

export default function Favorites() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all writing
        </div>
      </Link>

      <div className="text-2xl font-bold mb-2">
        Data Privacy and Social Authenticity in the Age of BeReal
      </div>

      <div className="text-gray-600 mb-6">
        4/21/2022
      </div>

      <div className="space-y-4">
        <p>
            On a Tuesday evening in February, on a video call with some friends, our conversation was interrupted by a minor panic among a couple of them. “Time for the BeReal!” they exclaimed, pulling out their phones. “Time for the be what?” I asked, guessing that this was just another novel pandemic trend (after all, this was right after the peak of the Wordle craze). They explained what the new social media app was and how popular it had become, nudging me to join as well, and after some convincing, I created an account. I’ve now been using BeReal every day since then, and despite my general wariness of social media (I’ve never used Snapchat or TikTok, for instance), it’s honestly been pretty fun to use.
        </p>
        <p>
            BeReal claims to buck the trend of careful <a href="https://www.huffpost.com/entry/the-facade-of-social-medi_b_5895994" className="underline">curation and self-presentation</a> that plagues so many social media platforms by bringing authenticity and spontaneity back to our online habits. Every day, at a random time, all users are prompted to take a photo of themselves and where they are (using both their front and back cameras simultaneously) in a two-minute window, with no preparation. This is then uploaded along with their geolocation, if they opt to turn location sharing on, and an optional caption. Friends who have also posted their own BeReals can then view, react to, and comment on these posts, and they disappear once the next BeReal prompt is sent out the following day. 
        </p>
        <p>
            Over the past few months, BeReal’s user base has <a href="https://www.wsj.com/articles/why-bereal-a-social-media-app-with-no-photo-filters-is-attracting-gen-z-11650456491?mod=flipboard" className="underline">grown rapidly</a>, especially among <a href="https://www.bloomberg.com/news/articles/2022-03-29/bereal-the-un-instagram-is-trending-at-college-campuses-by-keeping-it-casual" className="underline">college students</a> eager to share their daily lives and see what their close friends are really up to every day, supposedly free of the public-facing facades that other social media apps encourage users to curate. However, the rise of such a model focused on “authenticity” poses several troubling concerns. What are the implications of equating being “authentic” with sharing intimate levels of data with others? Even in comparison to its predecessors and competitors, BeReal has facilitated and desensitized us to a striking deterioration of personal privacy.
        </p>
        <p>
            One key consequence of making BeReal based on randomly timed prompts is that it <a href="https://yaledailynews.com/blog/2022/03/03/to-bereal-or-not-to-bereal/" className="underline">becomes a panopticon</a> of sorts, leading users to constantly worry—whether consciously or subconsciously—about when the BeReal prompt will appear each day. With no way of knowing when they will next need to put their life on display, users consequently strive to make every waking moment worthy of being shareable, thus defeating the original intention of personal authenticity, but also obliterating users’ privacy in the process. Instagram and Snapchat posts may be fake, curated versions of a user’s life, but the user at least has the ability to choose exactly what and when they want to share with others.
        </p>
        <p>
            The information that you share on BeReal is enough to build a fairly thorough profile of your daily life, from your habits and routines that can be inferred from what you do every morning or afternoon to the people you spend the most time with and the places you’re most often at. Because of this, users can choose to make their BeReals only viewable by their friends. However, BeReal does have the option to make posts public, and the app has a “Discover” tab where you can scroll through thousands of people’s posts, complete with their names, photos, and geolocations. BeReal’s <a href="https://bere.al/en/terms" className="underline">Terms of Service</a> warns against “collect[ing] information, data or Content about other Users without their permission,” but it’s not hard to imagine how this trove of personal data could be abused. Additionally, the app has even fewer privacy measures than other social media platforms—for instance, it lacks Snapchat’s feature of notifying users when others have taken screenshots of their posts—and goes beyond simply storing photos, as I found out one day when facial recognition detected that my face wasn’t in the frame and prompted me to retake the photo. 
        </p>
        <p>
        With every new social media platform nowadays, the expectation of user privacy is further diminished. When I brought up BeReal’s potential data surveillance and privacy issues with my friends, I found out that none of them had considered such concerns before signing up for an account. If one thing is clear from our societal norms of placing the burden of data and privacy considerations on users, it’s that our current use of user “consent” as a catchall for data usage and ownership is <a href="https://www.newamerica.org/oti/blog/how-notice-and-consent-fails-to-protect-our-privacy/" className="underline">far from adequate</a>. BeReal’s <a href="https://bere.al/en/privacy" className="underline">privacy policy</a> claims that the company does not sell users’ data for commercial purposes. But what about the next viral app? Will every single user read the privacy policy before signing up then? We can’t continue to rely on simply trusting companies to do the right thing. Instead, we need to rethink our current model of user consent and envision a future where staying connected with our friends doesn’t require us to surrender huge swaths of our personal data to corporations in order to be authentic and sincere. 
        </p>
      </div>
      
    </Layout>
  )
}